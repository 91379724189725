body {
  margin: 0;
  line-height: normal;
}

:root {
  /* fonts */
  --heading-h4-bold: 'DM Sans';
  --paragraph-small-regular: 'DM Sans';
  --paragraph-medium-medium: Inter;

  /* font sizes */
  --paragraph-small-regular-size: 14px;
  --paragraph-medium-regular-size: 16px;
  --heading-h5-regular-size: 24px;
  --heading-h4-bold-size: 28px;
  --paragraph-large-regular-size: 18px;
  --heading-h1-bold-size: 40px;
  --overline-small-size: 12px;
  --font-size-43xl: 62px;

  /* Colors */
  --secondary-50: #eff2fe;
  --generic-white: #fff;
  --color-gray-100: #fafafa;
  --color-gray-200: #404040;
  --neutral-9001: #111827;
  --neutral-900: #171717;
  --neutral-900-shadow: #1717174e;
  --secondary-300-main: #e1e7fd99;
  --secondary-400-main: #d0d6ec99;
  --secondary-500-main: #6466e9;
  --color-mediumslateblue-100: #4e46dd;
  --color-darkblue: #4138c2;
  --destructive-500: #ef4444;
  --neutral-100: #f5f5f5;
  --neutral-200: #e5e5e5;
  --neutral-300: #d4d4d4;
  --neutral-400: #a3a3a3;
  --neutral-500: #737373;
  --neutral-700: #404040;
  --color-darkgray: #a3a3a3;
  --generic-black: #000;
  --primary-50: #eef2ff;
  --color-lavender: #e0e7ff;
  --secondary-200: #c9d2fa;
  --color-cornflowerblue: #a8b4f7;

  /* Gaps */
  --gap-9xs: 4px;
  --gap-7xs: 6px;
  --gap-5xs: 8px;
  --gap-29xl: 48px;
  --gap-13xl: 32px;
  --gap-53xl: 72px;
  --gap-45xl: 64px;
  --gap-base: 16px;
  --gap-5xl: 24px;
  --gap-21xl: 40px;
  --gap-77xl: 96px;

  /* Paddings */
  --padding-53xl: 72px;
  --padding-3xs: 10px;
  --padding-xs: 12px;
  --padding-base: 16px;
  --padding-xl: 20px;
  --padding-5xl: 24px;
  --padding-13xl: 32px;
  --padding-17xl: 36px;
  --padding-21xl: 40px;
  --padding-35xl: 56px;
  --padding-61xl: 80px;
  --padding-81xl: 100px;
  --padding-85xl: 104px;
  --padding-181xl: 200px;

  /* border radiuses */
  --br-7xs: 6px;
  --br-10xs: 3px;
  --br-5xs-9: 7.9px;
  --br-xs: 12px;
  --br-base: 16px;
}
